/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (

    <div className="bio">
        <StaticImage
          className="bio-avatar"
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/profile-pic.jpg"
          width={50}
          height={50}
          quality={95}
          alt="Profile picture"
          />

        {author?.name && (
          <p>
            Written by <strong>{author.name}</strong> {author?.summary || null}
            {` `}
            {/* <a href={`https://twitter.com/${social?.twitter || ``}`}>
              You should follow them on Twitter
            </a> */}
            <div className="">
              <a className="button pseudo" target="_blank" href="mailto:jafarzadeh.farshad@outlook.com">
                <span className="icon is-small">
                    <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                </span>
              </a>
              <a className="button pseudo" target="_blank" href="https://www.researchgate.net/profile/Farshad_Jafarzadeh">
                  <span className="icon is-small">
                      <i className="fab fa-researchgate fa-2x" aria-hidden="true"></i>
                  </span>
              </a>
              <a className="button pseudo" target="_blank" href="https://www.linkedin.com/in/farshadj">
                  <span className="icon is-small">
                      <i className="fab fa-linkedin-in fa-2x" aria-hidden="true"></i>
                  </span>
              </a>
              <a className="button pseudo" target="_blank" href="https://scholar.google.com/citations?hl=en&user=rd7Cdq0AAAAJ">
                  <span className="icon is-small">
                      <i className="ai ai-google-scholar-square ai-2x" aria-hidden="true"></i>
                  </span>
              </a>
            </div>
          </p>
          
          )}
        
        
      </div>

  )
}

export default Bio
